import React from "react"
import { useStaticQuery, Link } from "gatsby"

import { rhythm, scale } from "../utils/typography"

const Footer = ({ location, title, children }) => {
  const data = useStaticQuery(graphql`
    query HeaderQuery {
      site {
        siteMetadata {
          title
        }
      }
      logo: file(absolutePath: { regex: "/small-saucepan-logo.svg/" }) {
        publicURL
      }
    }
  `)

  const Logo = ({ logoStyle }) => (
    <img
      src={data.logo.publicURL}
      style={Object.assign(
        {},
        {
          width: 25,
          height: 25,
          marginRight: "1rem",
        },
        logoStyle
      )}
    />
  )

  const rootPath = `${__PATH_PREFIX__}/`
  let header

  return (
    <footer
      style={{
        maxWidth: rhythm(24),
        marginLeft: `auto`,
        marginRight: `auto`,
        padding: `${rhythm(1.5)} ${rhythm(3 / 4)}`,
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
        }}
      >
        <section>
          <p>Small Saucepan Pty Ltd</p>
          <p>
            <a
              style={{ color: "#0093e5" }}
              href="mailto:james@smallsaucepan.com"
            >
              james@smallsaucepan.com
            </a>
            <br />
            <a style={{ color: "#0093e5" }} href="tel:+61438734634">
              0438 734 634
            </a>
          </p>
          <p>
            ACN: 644549806
            <br />
            ABN: 38644549806
          </p>
        </section>
        {/* <section>Copyright</section>
        <section>
          © {new Date().getFullYear()}, Built with
          {` `}
          <a href="https://www.gatsbyjs.org">Gatsby</a>
        </section> */}
      </div>
    </footer>
  )
}

export default Footer

import React from "react"
import { useStaticQuery, Link } from "gatsby"

import { rhythm, scale } from "../utils/typography"

import Footer from "./footer"

const Layout = ({ location, title, children }) => {
  const data = useStaticQuery(graphql`
    query FooterQuery {
      site {
        siteMetadata {
          title
        }
      }
      logo: file(absolutePath: { regex: "/smallsaucepan-logo.svg/" }) {
        publicURL
      }
    }
  `)

  const Logo = ({ logoStyle }) => (
    <img
      src={data.logo.publicURL}
      style={Object.assign(
        {},
        {
          width: 25,
          height: 25,
          marginRight: "1rem",
        },
        logoStyle
      )}
    />
  )

  const rootPath = `${__PATH_PREFIX__}/`
  let header

  if (location.pathname === rootPath) {
    header = (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          // border: "1px solid red",
        }}
      >
        <Link
          style={{
            boxShadow: `none`,
            color: `inherit`,
          }}
          to={`/`}
        >
          <Logo logoStyle={{ width: 50, height: 50 }} />
        </Link>
        <h1
          style={{
            ...scale(1.4),
            marginBottom: rhythm(1.5),
            marginTop: 0,
            // border: "1px solid red",
          }}
        >
          <Link
            style={{
              boxShadow: `none`,
              color: `inherit`,
            }}
            to={`/`}
          >
            {title}
          </Link>
        </h1>
      </div>
    )
  } else {
    header = (
      <div
        style={{
          display: "flex",
          alignItems: "flex-start",
        }}
      >
        <Link
          style={{
            boxShadow: `none`,
            color: `inherit`,
          }}
          to={`/`}
        >
          <img
            src={data.logo.publicURL}
            style={{
              width: 25,
              height: 25,
              marginRight: "1rem",
            }}
          />
        </Link>
        <h3
          style={{
            fontFamily: `Montserrat, sans-serif`,
            marginTop: 0,
          }}
        >
          <Link
            style={{
              boxShadow: `none`,
              color: `inherit`,
            }}
            to={`/`}
          >
            {title}
          </Link>
        </h3>
      </div>
    )
  }
  return (
    <div
      style={{ minHeight: "100vh", display: "flex", flexDirection: "column" }}
    >
      <div
        style={{
          marginLeft: `auto`,
          marginRight: `auto`,
          maxWidth: rhythm(24),
          padding: `${rhythm(1.5)} ${rhythm(3 / 4)}`,
          flexGrow: 1,
        }}
      >
        <header>{header}</header>
        <main style={{ flex: "1 0 auto" }}>{children}</main>
      </div>
      <div
        style={{
          backgroundImage:
            "linear-gradient(171deg, rgba(8,8,8,1) 0%, rgba(50,42,42,1) 56%, rgba(38,38,38,1) 100%)",
          clipPath: `polygon(
            0 10%,
            100% 0,
            100% 100%,
            0 100%
          )`,
          color: "white",
          paddingTop: rhythm(1.5),
        }}
      >
        <Footer />
      </div>
    </div>
  )
}

export default Layout
